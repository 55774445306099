<template>
  <div class="content">
    <div v-if="!asParent">
      <div class="flex">
        <label>参数名（仅英文）：</label>
        <a-input :disabled="asParam" style="width: 160px" v-model:value="paramData.name" @change="optionChange" />
      </div>
      <div class="flex">
        <label>描述：</label>
        <a-input :disabled="asParam" style="width: 160px" v-model:value="paramData.description" @change="optionChange" />
      </div>
    </div>
    <div v-for="(item, index) in paramData.list" :key="index">
      <div class="flex">
        <label>参数类型：</label>
        <a-select
          style="width: 160px"
          :value="item.type"
          @change="changeType($event, index)"
        >
          <a-select-option v-for="val in typeList" :key="val" :value="val">
            {{val}}
          </a-select-option>
        </a-select>
        <button class="delete_param" @click="remove(index)">删除</button>
      </div>
      <div v-if="item.type === 'string'">
        <StringParam
          :asParam="asParam"
          :option="paramData.list[index]"
          @optionChange="
            paramData.list[index] = $event;
            optionChange;
          "
        />
      </div>
      <div v-else-if="item.type === 'number'">
        <NumberParam
          :asParam="asParam"
          :option="paramData.list[index]"
          @optionChange="
            paramData.list[index] = $event;
            optionChange;
          "
        />
      </div>
      <div v-else-if="item.type === 'boolean'">
        <BooleanParam
          :asParam="asParam"
          :option="paramData.list[index]"
          @optionChange="
            paramData.list[index] = $event;
            optionChange;
          "
        />
      </div>
      <div v-else-if="item.type === 'object'">
        <ObjectParam
          :asParam="asParam"
          :option="paramData.list[index]"
          @optionChange="
            paramData.list[index] = $event;
            optionChange;
          "
        />
      </div>
      <div v-else-if="item.type === 'array'">
        <ArrayParam
          :asParam="asParam"
          :option="paramData.list[index]"
          @optionChange="
            paramData.list[index] = $event;
            optionChange;
          "
        />
      </div>
    </div>
    <div v-if="!asParam" class="add_param" @click="add">添加参数</div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { typeList } from '../formwork.model';
import { ParamArray, ParamBoolean, ParamNumber, ParamObject, ParamString } from './param.model';
@Options({
  components: {},
  props: {
    option: ParamObject,
    asParam: Boolean,
    asParent: Boolean,
  },
  emits: {
    changeOption: ParamObject,
  },
})
export default class ObjectParam extends Vue {
  private option!: ParamObject;
  private asParam?: boolean = false;
  private asParent?: boolean = false;
  set paramData(value: ParamObject) {
    this.option = value;
  }
  get paramData(): ParamObject {
    return this.option;
  }
  private readonly typeList = typeList;
  public add() {
    if (this.paramData.list) {
      this.paramData.list.push(this.newParam());
    } else {
      this.paramData.list = [this.newParam()];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public changeType(type: string, index: number) {
    console.log(type);
    this.paramData.list[index] = this.newParam(type);
    this.$forceUpdate();
  }
  // tslint:disable-next-line:max-line-length
  public newParam(type: string = 'string'): ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray | undefined {
    switch (type) {
      case 'string': return new ParamString();
      case 'number': return new ParamNumber();
      case 'boolean': return new ParamBoolean();
      case 'array': return new ParamArray();
      case 'object': return new ParamObject();
      default: break;
    }
  }
  public optionChange() {
    this.$emit('optionChange', this.paramData);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  margin: 5px;
  padding: 15px;
  flex: 1;
  height: fit-content;
  background: rgba(163, 163, 163, 0.1);
}
.flex {
  align-items: center;
}
.a-input {
  width: 120px;
}
.a-select {
  width: 120px;
}
label {
  width: 140px;
}
.add_param{
  font-size: 14px;
  color: #1890FF;
  text-decoration: underline;
}
.delete_param{
  color: #FF4949;
  font-size: 14px;
  text-decoration: underline;
}
</style>
