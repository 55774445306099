<template>
  <div class="home full flex">
    <div class="window">欢迎使用参数配置平台</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {},
})
export default class Login extends Vue {
  public mounted() {
    console.log(this.$route.query);
    const jwt = this.$route.query.jwt;
    if (jwt) {
      this.$storage.save('jwt', jwt).then(() => {
        this.$router.push('/public/vehicle');
      });
    } else {
      this.$base.error('缺失登录信息');
      this.$base.logeout();
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  align-items: center;
  justify-content: center;
  .window {
    font-size: 40px;
    font-weight: 600;
  }
}
</style>