<template>
  <div class="content">
    <div class="flex width-fit" v-if="!asParent">
      <label>参数名（仅英文）：</label>
      <a-input
       :disabled="asParam"
        style="width: 160px"
        v-model:value="paramData.name"
        @change="optionChange"
      />
    </div>
    <div class="flex width-fit" v-if="!asParent">
      <label>描述：</label>
      <a-input
       :disabled="asParam"
        style="width: 160px"
        v-model:value="paramData.description"
        @change="optionChange"
      />
    </div>
    <div class="flex width-fit">
      <label>参数类型：</label>
      <a-select
        style="width: 160px"
        v-model:value="paramData.arrayType"
        @change="changeType"
      >
        <a-select-option v-for="type in typeList" :key="type" :value="type">{{
          type
        }}</a-select-option>
      </a-select>
      <button
        v-if="asParam?asParam: (paramData.arrayType !== 'string'&&paramData.arrayType !== 'number')"
        class="add_param"
        @click="add"
      >
        添加参数
      </button>
    </div>
    <div v-for="(item, index) in paramData.list" :key="index">
      <div class="flex" style="align-items: flex-start;">
        <div v-if="paramData.arrayType === 'string'">
          <a-input
            style="width: 160px"
            v-model:value="paramData.list[index]"
            @change="optionChange;"
          />
        </div>
        <div v-else-if="paramData.arrayType === 'number'">
          <a-input-number
            style="width: 160px"
            v-model:value="paramData.list[index]"
            @change="optionChange;"
          />
        </div>
        <div v-else-if="paramData.arrayType === 'object'">
          <ObjectParam
            :option="paramData.list[index]"
            @optionChange="
              paramData.list[index] = $event;
              optionChange;
            "
            :asParent="true"
          />
        </div>
        <div v-else-if="paramData.arrayType === 'array'">
          <ArrayParam
            :option="paramData.list[index]"
            @optionChange="
              paramData.list[index] = $event;
              optionChange;
            "
            :asParent="true"
          />
        </div>
        <button class="delete_param" @click="remove(index)">删除</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { typeList } from '../formwork.model';
import {
  ParamArray,
  ParamBoolean,
  ParamNumber,
  ParamObject,
  ParamString,
} from './param.model';
@Options({
  components: {},
  props: {
    option: ParamArray,
    asParam: Boolean,
    asParent: Boolean,
  },
  emits: {
    optionChange: Object,
  },
})
export default class ArrayParam extends Vue {
  private option!: ParamArray;
  private asParam?: boolean;
  private asParent?: boolean;
  set paramData(value: ParamArray) {
    this.option = value;
  }
  get paramData(): ParamArray {
    return this.option;
  }
  private readonly typeList = typeList.filter((r) => r !== 'boolean' && r !== 'array');
  public add() {
    const newParam = this.newParam(this.paramData.arrayType);
    if (this.paramData.list) {
      this.paramData.list.push(newParam);
    } else {
      this.paramData.list = [newParam];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public changeType() {
    const newParam = this.newParam(this.paramData.arrayType);
    if (newParam) {
      this.paramData.list = [this.newParam(this.paramData.arrayType)];
    } else {
      this.paramData.list = [];
    }
    this.$forceUpdate();
  }
  public newParam(type: string = 'string') {
    switch (type) {
      case 'string':
        return null;
      case 'number':
        return null;
      case 'object':
        return new ParamObject();
      default: return null;
    }
  }
  public optionChange() {
    // console.log(this.paramData);
    this.$emit('optionChange', this.paramData);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  margin: 5px;
  padding: 15px;
  flex: 1;
  height: fit-content;
  background: rgba(163, 163, 163, 0.1);
}
.flex {
  align-items: center;
}
.a-input {
  width: 120px;
}
.a-select {
  width: 120px;
}
label {
  width: 140px;
}
.add_param {
  font-size: 14px;
  color: #1890ff;
  text-decoration: underline;
}
.delete_param {
  color: #ff4949;
  font-size: 14px;
  text-decoration: underline;
  line-height: 30px;
  margin-top: 20px;
}
</style>
