
import { Switch } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import { ParamNumber } from './param.model';
@Options({
  components: {
    'a-switch': Switch,
  },
  props: {
    option: ParamNumber,
    asParam: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    optionChange: Object,
  },
})
export default class NumberParam extends Vue {
  private option!: ParamNumber;
  private asParam?: boolean = false;
  private max = Infinity;
  private min = -Infinity;
  set paramData(value: ParamNumber) {
    this.option = value;
  }
  get paramData(): ParamNumber {
    return this.option;
  }
  public changeMax() {
    if (this.paramData.setMax) {
      if (this.paramData.max !== null && this.paramData.min !== null) {
        if (this.paramData.max < this.paramData.min) {
          this.paramData.max = this.paramData.min;
        }
        if (this.paramData.value || this.paramData.value === 0) {
          if (this.paramData.max < this.paramData.value) {
            this.paramData.value = this.paramData.max;
          }
        }
      }
    }
    this.optionChange();
  }
  public changeMin() {
    if (this.paramData.setMin) {
      if (this.paramData.max !== null && this.paramData.min !== null) {
        if (this.paramData.min > this.paramData.max) {
          this.paramData.min = this.paramData.max;
        }
        if (this.paramData.setMin && this.paramData.value) {
          if (this.paramData.min > this.paramData.value) {
            this.paramData.value = this.paramData.min;
          }
        }
      }
    }
    this.optionChange();
  }

  public add() {
    if (this.paramData.list) {
      this.paramData.list.push({ value: null, description: '' });
    } else {
      this.paramData.list = [{ value: null, description: '' }];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public optionChange() {
    this.$emit('optionChange', this.paramData);
    this.$forceUpdate();
  }
}
