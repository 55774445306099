
import { Options, Vue } from 'vue-class-component';
import { ParamBoolean } from './param.model';
@Options({
  components: {},
  props: {
    option: ParamBoolean,
    asParam: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    optionChange: Object,
  },
})
export default class BooleanParam extends Vue {
  private option!: ParamBoolean;
  private asParam?: boolean;
  set paramData(value: ParamBoolean) {
    this.option = value;
  }
  get paramData(): ParamBoolean {
    return this.option;
  }
  public optionChange(op: any) {
    this.$emit('optionChange', this.paramData);
  }
}
