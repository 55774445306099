<template>
  <div class="param-padding">
    <div class="row flex" v-for="(param, index) in paramData" :key="index">
      <div>
        {{ param.name?"\""+param.name+"\"\:":'' }}
      </div>
      <div v-if="param.type === 'string' || param.type === 'number' || param.type === 'boolean'">
        {{ param.value ? param.value : "null" }};
        <span class="span">{{ param.description?'\/\/ '+param.description:'' }}</span>
      </div>
      <div v-else-if="param.type === 'object' && param.list.length">
        {
            <ShowParam :params="param.list" />
        }<span class="span">{{ param.description?'\/\/ '+param.description:'' }}</span>
      </div>
      <div v-else-if="param.type === 'array'">
        [
            <div v-if="param.list.length">
                <ShowParam :params="param.list" />
            </div>
        ]<span class="span">{{ param.description?'\/\/ '+param.description:'' }}</span>
      </div>
      <div v-else-if="!param.type">
          {{param}}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Select } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import {
  ParamString,
  ParamNumber,
  ParamBoolean,
  ParamObject,
  ParamArray,
} from './formwork/params/param.model';
@Options({
  components: {},
  props: {
    params: Array,
  },
})
export default class ShowParam extends Vue {
  private params!: Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  >;
  set paramData(
    value: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >,
  ) {
    this.params = value;
  }
  get paramData(): Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  > {
    return this.params;
  }
}
</script>
<style lang="less">
.param-padding {
  padding-left: 10px;
  .row{
    align-items: flex-start;
    .span{
      margin-left: 5px;
    }
  }
}
</style>