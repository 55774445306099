import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/style.less';
import storageService from './core/storage.service';
import httpService from './core/http.service';
import baseService from './core/base.service';
// import global from '../config/global';
// const app = createApp(App);
// app.config.productionTip = false;
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $storage: {
            get: <T>(key: string) => Promise<T | any>;
            save: <T>(key: string, value: T) => Promise<T | any>;
            delete: (key: string) => Promise<any>;
            clear: () => Promise<any>;
            test: () => {}
        };
        $http: {
            get: <T>(url: string, params: any, config?: HttpConfig) => Promise<T>;
            post: <T>(url: string, data: any, config?: HttpConfig) => Promise<T>;
            put: <T>(url: string, data: any, config?: HttpConfig) => Promise<T>;
            delete: <T>(url: string, params: any, config?: HttpConfig) => Promise<T>;
        };
        $base: {
            getSuppliers: () => Promise<Array<{supplier: string}>>;
            getTypes: (supplier: string) => Promise<Array<{type: string}>>;
            getVersions: (supplier: string, type: string) => Promise<Array<{version: string}>>;
            info: (msg: string, callBack?: () => any, title?: string) => void;
            success: (msg: string, callBack?: () => void) => void;
            error: (msg: string, title?: string, callBack?: () => void) => void;
            warning: (msg: string, callBack?: () => void) => void;
            logeout: () => void;
            loading: {
                show: (msg?: string) => {};
                close: () => {};
            }
        };
    }
}
const app = createApp(App);
app.use(store);
app.use(router);
app.mount('#app');
app.config.globalProperties.$http = httpService;
app.config.globalProperties.$storage = storageService;
app.config.globalProperties.$base = baseService;
// app.config.globalProperties.env = global;

import { Input, Select, Table, InputNumber, DatePicker, Button } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { HttpConfig } from './core/http.model';
import ArrayParam from '@/components/formwork/params/ArrayParam.vue';
import NumberParam from '@/components/formwork/params/NumberParam.vue';
import ObjectParam from '@/components/formwork/params/ObjectParam.vue';
import StringParam from '@/components/formwork/params/StringParam.vue';
import BooleanParam from '@/components/formwork/params/BooleanParam.vue';
import ShowParam from '@/components/showParam.vue';
import SetParam from '@/components/setParam.vue';
app.component('a-input', Input);
app.component('a-input-number', InputNumber);
app.component('a-button', Button);
app.component('a-select', Select);
app.component('a-select-option', Select.Option);
app.component('a-table', Table);
app.component('ArrayParam', ArrayParam);
app.component('BooleanParam', BooleanParam);
app.component('NumberParam', NumberParam);
app.component('ObjectParam', ObjectParam);
app.component('StringParam', StringParam);
app.component('ShowParam', ShowParam);
app.component('SetParam', SetParam);
