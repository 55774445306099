<template>
  <div class="content">
    <div class="flex">
      <label>参数名（仅英文）：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.name" @change="optionChange" />
    </div>
    <div class="flex">
      <label>描述：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.description" @change="optionChange" />
    </div>
    <div class="flex" v-if="asParam">
      <label>值：</label>
      <a-select style="width: 160px" v-model:value="paramData.value" placeholder="请选择" @change="optionChange">
        <a-select-option value="true">
          true
        </a-select-option>
        <a-select-option value="false">
          false
        </a-select-option>
      </a-select>
    </div>
    <div class="flex">
      <label>true值描述：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.trueDesc" @change="optionChange" />
    </div>
    <div class="flex">
      <label>false值描述：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.falseDesc" @change="optionChange" />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ParamBoolean } from './param.model';
@Options({
  components: {},
  props: {
    option: ParamBoolean,
    asParam: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    optionChange: Object,
  },
})
export default class BooleanParam extends Vue {
  private option!: ParamBoolean;
  private asParam?: boolean;
  set paramData(value: ParamBoolean) {
    this.option = value;
  }
  get paramData(): ParamBoolean {
    return this.option;
  }
  public optionChange(op: any) {
    this.$emit('optionChange', this.paramData);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  margin: 5px;
  padding: 15px;
  flex: 1;
  height: fit-content;
  background: rgba(163, 163, 163, 0.1);
}
.flex1 {
}
.flex {
  align-items: center;
}
.a-input {
  width: 120px;
}
.a-select {
  width: 120px;
}
label {
  width: 140px;
}
</style>
