<template>
  <div class="content">
    <div class="flex">
      <label>参数名（仅英文）：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.name" @change="optionChange()" />
    </div>
    <div class="flex">
      <label>描述：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.description" @change="optionChange()" />
    </div>
    <div class="flex" v-if="asParam">
      <label>值：</label>
      <a-input style="width: 160px" v-model:value="paramData.value" @change="optionChange()" />
    </div>
    <div class="flex" v-if="!asParam">
      <button :disabled="!paramData?.type" class="add_param" @click="add" v-if="!asParam">添加枚举</button
      >
    </div>
    <div
      class="flex"
      v-for="(item, index) in paramData.list"
      :key="index"
      style="justify-content: flex-start"
    >
      <a-input
        v-if="paramData?.type === 'string'"
        placeholder="请输入值"
        style="width: 160px;margin-right: 10px"
        v-model:value="paramData.list[index].value"
        @change="optionChange()"
      />
      <a-input
        placeholder="请输入描述"
        style="width: 160px;margin-right: 10px"
        v-model:value="paramData.list[index].description"
        @change="value;optionChange()"
      />
      <div class="flex">
        <button class="delete_param" @click="remove(index)" v-if="!asParam">删除</button>
      </div>
    </div>
  </div>
</template>
<script lang='ts'>
import { Options, Vue } from 'vue-class-component';
import { ParamString } from './param.model';
@Options({
  components: {},
  props: {
    option: ParamString,
    asParam: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    optionChange: Object,
  },
})
export default class StringParam extends Vue {
  private option!: ParamString;
  private asParam = false;
  set paramData(value: ParamString) {
    this.option = value;
  }
  get paramData(): ParamString {
    return this.option;
  }
  public add() {
    if (this.paramData.list) {
      this.paramData.list.push({ value: null, description: '' });
    } else {
      this.paramData.list = [{ value: null, description: '' }];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public optionChange() {
    this.$emit('optionChange', this.paramData);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  margin: 5px;
  padding: 15px;
  flex: 1;
  height: fit-content;
  background: rgba(163, 163, 163, 0.1);
}
.flex {
  align-items: center;
}
.a-input {
  width: 120px;
}
label {
  width: 140px;
}
.add_param{
  font-size: 14px;
  color: #1890FF;
  text-decoration: underline;
}
.delete_param{
  color: #FF4949;
  font-size: 14px;
  text-decoration: underline;
}
</style>
