
import { Select } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import {
  ParamString,
  ParamNumber,
  ParamBoolean,
  ParamObject,
  ParamArray,
} from './formwork/params/param.model';
@Options({
  components: {},
  props: {
    params: Array,
  },
})
export default class ShowParam extends Vue {
  private params!: Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  >;
  set paramData(
    value: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >,
  ) {
    this.params = value;
  }
  get paramData(): Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  > {
    return this.params;
  }
}
