<template>
  <div class="param-padding">
    <div class="row flex" v-for="(param, index) in paramData" :key="index">
      <div class="row-title">
        {{ param.name }}{{param.description?'（'+param.description+'）': ''}}
      </div>
      <div v-if="param.type === 'string'">
        <a-input :disabled="ifPreview" v-if="!param.list.length"  v-model:value="param.value" style="width: 160px" />
        <a-select :disabled="ifPreview" v-else v-model:value="param.value" style="width: 160px">
          <a-select-option v-for="(val, index) in param.list" :value="val.value" :key="index">{{val.value}}{{val.description?'('+val.description+')':''}}</a-select-option>
        </a-select>
      </div>
      <div v-if="param.type === 'number'">
        <a-input-number
         :disabled="ifPreview"
          v-if="!param.list.length" 
          v-model:value="param.value"
          controls-position="right"
          style="width: 160px"
          :min="param.setMin === false ? -Infinity : param.min"
          :max="param.setMax  === false ? Infinity : param.max"
        />
        <a-select :disabled="ifPreview" v-else v-model:value="param.value" style="width: 160px">
          <a-select-option v-for="(val, index) in param.list" :value="val.value" :key="index">{{val.value}}{{val.description?'('+val.description+')':''}}</a-select-option>
        </a-select>
      </div>
      <div class="flex1" v-else-if="param.type === 'object'">
        <SetParam :ifPreview="ifPreview" v-model:params="param.list" />
      </div>
      <div class="flex1 column" v-else-if="param.type === 'array'">
        <div v-for="(item,i) in param.value" :key="i" class="flex">
          <div v-if="param.arrayType === 'string'">
            <a-input :disabled="ifPreview" style="width: 160px" v-model:value="param.value[i]" />
          </div>
          <div v-else-if="param.arrayType === 'number'">
            <a-input-number :disabled="ifPreview" style="width: 160px" v-model:value="param.value[i]" />
          </div>
          <div v-else-if="param.arrayType === 'object'">
            <!-- object：{{param.value[i]}} -->
            <SetParam :ifPreview="ifPreview" v-model:params="param.value[i].list" />
          </div>
          <div @click="deleteArray(param, i)" class="nowrap">
            删除
          </div>
        </div>
        <div class="blue-button" @click="add(param, param.model)">添加参数</div>
      </div>
      <!-- <div v-else-if="!param.type">
        {{param}}
      </div> -->
    </div>
  </div>
</template>
<script lang="ts">
import { Select } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import {
  ParamString,
  ParamNumber,
  ParamBoolean,
  ParamObject,
  ParamArray,
} from './formwork/params/param.model';
@Options({
  components: {},
  props: {
    defaultValue: Object,
    params: Array,
    ifPreview: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:params': Array,
  },
})
export default class SetParam extends Vue {
  private params!: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >;
  set paramData(
    value: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >,
  ) {
    this.params = value;
  }
  get paramData(): Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    > {
    return this.params;
  }
  private ifPreview!: boolean;
  private defaultValue?: any;
  public add(param: ParamArray, model: object) {
    // console.log(model);
    if (param.arrayType === 'string') {
      param.value.push('');
    } else if (param.arrayType === 'number') {
      param.value.push(null);
    } else if (param.arrayType === 'object') {
      // console.log(this.paramData);
      const target = JSON.parse(JSON.stringify(model));
      param.value.push(target as any);
    }
    // console.log(param);
  }
  public deleteArray(param: ParamArray, index: number) {
    param.value.splice(index, 1);
  }
}
</script>
<style lang="less">
.param-padding {
  background: rgba(163, 163, 163, 0.1);
  padding: 10px;
  min-width: fit-content;
  .row{
    .row-title{
      margin-right:10px;
      white-space: nowrap;
    }
    min-width: fit-content;
    align-items: flex-start;
    line-height: 32px;
    margin: 5px 0px;
    .blue-button{
      width: fit-content;
    }
  }
}
.nowrap{
  white-space: nowrap;
  color: #1890FF;
  text-decoration: underline;
  line-height: 32px;
  padding: 10px 15px;
}
</style>