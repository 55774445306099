
import { Select } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import {
  ParamString,
  ParamNumber,
  ParamBoolean,
  ParamObject,
  ParamArray,
} from './formwork/params/param.model';
@Options({
  components: {},
  props: {
    defaultValue: Object,
    params: Array,
    ifPreview: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:params': Array,
  },
})
export default class SetParam extends Vue {
  private params!: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >;
  set paramData(
    value: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >,
  ) {
    this.params = value;
  }
  get paramData(): Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    > {
    return this.params;
  }
  private ifPreview!: boolean;
  private defaultValue?: any;
  public add(param: ParamArray, model: object) {
    // console.log(model);
    if (param.arrayType === 'string') {
      param.value.push('');
    } else if (param.arrayType === 'number') {
      param.value.push(null);
    } else if (param.arrayType === 'object') {
      // console.log(this.paramData);
      const target = JSON.parse(JSON.stringify(model));
      param.value.push(target as any);
    }
    // console.log(param);
  }
  public deleteArray(param: ParamArray, index: number) {
    param.value.splice(index, 1);
  }
}
