import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/public',
    name: 'public',
    component: () => import('../views/Public.vue'),
    children: [
      {
        path: 'vehicle',
        component: () => import('../views/vehicle-list/vehicle-list.vue'),
      },
      {
        path: 'mission',
        component: () => import('../views/mission-list/mission-list.vue'),
      },
      {
        path: 'formwork',
        component: () => import('../views/formwork-list/formwork-list.vue'),
      },
      {
        path: 'formwork-detail',
        component: () => import('../views/formwork-detail/formwork-detail.vue'),
      },
      {
        path: 'presupposition',
        component: () => import('../views/presupposition-list/presupposition-list.vue'),
      },
      {
        path: 'presupposition-detail',
        component: () => import('../views/presupposition-detail/presupposition-detail.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/public/vehicle',
    name: 'not-found'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
