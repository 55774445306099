<template>
  <div class="content">
    <div class="flex">
      <label>参数名（仅英文）：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.name" @change="optionChange" />
    </div>
    <div class="flex">
      <label>描述：</label>
      <a-input style="width: 160px" :disabled="asParam" v-model:value="paramData.description" @change="optionChange" />
    </div>
    <div class="flex">
      <label
        >最大：
        <a-switch :disabled="asParam" v-model:checked="paramData.setMax" @change="changeMax" />
      </label>
      <a-input-number
        style="width: 160px"
        :disabled="!paramData.setMax || asParam"
        v-model:value="paramData.max"
        @change="changeMax"
      />
    </div>
    <div class="flex">
      <label>
        最小：
        <a-switch :disabled="asParam" v-model:checked="paramData.setMin" @change="changeMin" />
      </label>
      <a-input-number
        style="width: 160px"
        :disabled="!paramData.setMin || asParam"
        v-model:value="paramData.min"
        @change="changeMin"
      />
    </div>
    <div class="flex" v-if="asParam">
      <label>值：</label>
      <a-input-number
        style="width: 160px"
        controls-position="right"
        :min="paramData.setMin === null ? -Infinity : paramData.min"
        :max="paramData.setMax  === null ? Infinity : paramData.max"
        @change="optionChange"
      />
    </div>
    <div class="flex">
      <button v-if="!asParam" class="add_param" @click="add">添加枚举</button>
    </div>
    <div
      class="flex"
      v-for="(item, index) in paramData.list"
      :key="index"
      style="justify-content: flex-start"
    >
      <a-input-number
        style="width: 160px;margin-right: 10px"
        placeholder="请输入值"
        v-model:value="paramData.list[index].value"
        @keyup="forceUpdate"
        @change="optionChange"
      />
      <a-input
        style="width: 160px;margin-right: 10px"
        placeholder="请输入描述"
        v-model:value="paramData.list[index].description"
        @change="optionChange"
      />
      <div class="flexv-center">
        <button v-if="!asParam" class="delete_param" @click="remove(index)">删除</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Switch } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import { ParamNumber } from './param.model';
@Options({
  components: {
    'a-switch': Switch,
  },
  props: {
    option: ParamNumber,
    asParam: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    optionChange: Object,
  },
})
export default class NumberParam extends Vue {
  private option!: ParamNumber;
  private asParam?: boolean = false;
  private max = Infinity;
  private min = -Infinity;
  set paramData(value: ParamNumber) {
    this.option = value;
  }
  get paramData(): ParamNumber {
    return this.option;
  }
  public changeMax() {
    if (this.paramData.setMax) {
      if (this.paramData.max !== null && this.paramData.min !== null) {
        if (this.paramData.max < this.paramData.min) {
          this.paramData.max = this.paramData.min;
        }
        if (this.paramData.value || this.paramData.value === 0) {
          if (this.paramData.max < this.paramData.value) {
            this.paramData.value = this.paramData.max;
          }
        }
      }
    }
    this.optionChange();
  }
  public changeMin() {
    if (this.paramData.setMin) {
      if (this.paramData.max !== null && this.paramData.min !== null) {
        if (this.paramData.min > this.paramData.max) {
          this.paramData.min = this.paramData.max;
        }
        if (this.paramData.setMin && this.paramData.value) {
          if (this.paramData.min > this.paramData.value) {
            this.paramData.value = this.paramData.min;
          }
        }
      }
    }
    this.optionChange();
  }

  public add() {
    if (this.paramData.list) {
      this.paramData.list.push({ value: null, description: '' });
    } else {
      this.paramData.list = [{ value: null, description: '' }];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public optionChange() {
    this.$emit('optionChange', this.paramData);
    this.$forceUpdate();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  margin: 5px;
  padding: 15px;
  flex: 1;
  height: fit-content;
  background: rgba(163, 163, 163, 0.1);
}
.add_param{
  font-size: 14px;
  color: #1890FF;
  text-decoration: underline;
}
.flex {
  align-items: center;
}
.a-input {
  width: 120px;
}
.a-input-number {
  width: 120px;
}
label {
  width: 140px;
}
.delete_param{
  color: #FF4949;
  font-size: 14px;
  text-decoration: underline;
}
</style>
