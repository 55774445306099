
/* eslint-disable */
import axios, { AxiosError, AxiosPromise } from 'axios';
import { HttpConfig } from './http.model';
import base from './base.service';
import storage from './storage.service';

const Http = {
    get token(): Promise<string> {
        return storage.get('jwt');
    },
    dealParam(object: any) {
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] === undefined || object[key] === '') {
                    delete object[key];
                }
            }
        }
        return object;
    },
    dealError(e: AxiosError): string {
        // console.log(process.env);
        let msg: string = '';
        const error: {errcode: string, errmsg: string} = e.response?.data;
        if (error && error.errmsg) {
            msg = error.errmsg;
        } else {
            // console.log(e);
            const code = e.response?.status;
            const closePage = () => {
                console.log('closePage');
                msg = '登录信息失效';
                base.logeout();
            };
            switch (code) {
                case 400: msg = '参数错误'; break;
                case 504: msg = '网络超时'; break;
                case 500: msg = '系统错误'; break;
                case 401: closePage(); break;
                case 403: closePage(); break;
            }
        }
        return msg;
    },
    async get<T>(url: string, params: any, config = new HttpConfig()): Promise<T> {
        return axios({
            method: 'get',
            responseType: config.responseType,
            url,
            headers: {
                Authorization: 'Bearer ' + await this.token,
            },
            params: this.dealParam(params),
            timeout: config.timeout,
        }).then((r) => {
            return r.data;
        }).catch((e) => {
            throw this.dealError(e);
        });
    },
    async delete<T>(url: string, params: any, config = new HttpConfig()): Promise<T> {
        return axios({
            method: 'delete',
            responseType: config.responseType,
            url,
            headers: {
                Authorization: 'Bearer ' + await this.token,
            },
            params: this.dealParam(params),
            timeout: config.timeout,
        }).then((r) => {
            return r.data;
        }).catch((e) => {
            throw this.dealError(e);
        });
    },
    async post<T>(url: string, data: any, config = new HttpConfig()): Promise<T> {
        return axios({
            method: 'post',
            responseType: config.responseType,
            url,
            data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + await this.token,
            },
            timeout: config.timeout,
        }).then((r) => {
            return r.data;
        }).catch((e) => {
            throw this.dealError(e);
        });
    },
    async put<T>(url: string, data: any, config = new HttpConfig()): Promise<T> {
        return axios({
            method: 'put',
            responseType: config.responseType,
            url,
            data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + await this.token,
            },
            timeout: config.timeout,
        }).then((r) => {
            return r.data;
        }).catch((e) => {
            throw this.dealError(e);
        });
    },
};

export default Http;
// {
//     install(Vue: any, options: any) {
//         // Vue.prototype.$http = Http;
//     },
// };
