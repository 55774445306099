import { Modal } from 'ant-design-vue';
import { AxiosPromise } from 'axios';
import Http from './http.service';
import storage from './storage.service';
import { h } from 'vue';

const BaseService = {
    baseUrl: process.env.VUE_APP_API,
    // 获取供应商列表
    getSuppliers(): Promise<Array<{ supplier: string }>> {
        return Http.get<Array<{ supplier: string }>>(this.baseUrl + '/devices/suppliers', {});
    },
    // 获取设备版本列表
    getTypes(supplier: string): Promise<Array<{ type: string }>> {
        console.log('getTypes-supplier', supplier);
        return Http.get<Array<{ type: string }>>(this.baseUrl + '/devices/types', { supplier });
    },
    // 获取设备版本列表
    getVersions(supplier: string, type: string): Promise<Array<{ version: string }>> {
        return Http.get<Array<{ version: string }>>(this.baseUrl + '/devices/versions', { supplier, type });
    },
    // 弹窗
    // tslint:disable-next-line:no-empty
    info(msg: string, callBack: () => any = () => { }, title: string = '提示') {
        Modal.confirm({
            title,
            okText: '确认',
            cancelText: '取消',
            content: h('div', {}, [
                h('p', msg),
            ]),
            onOk() {
                callBack();
            },
        });
    },
    success(msg: string, callBack?: () => void) {
        Modal.success({
            title: '成功',
            content: h('div', {}, [
                h('p', msg),
            ]),
            onOk() {
                if (callBack) {
                    callBack();
                }
            },
        });
    },
    error(msg: string, title: string = '错误', callBack?: () => void) {
        Modal.error({
            title,
            content: msg,
            onOk() {
                if (callBack) {
                    callBack();
                }
            },
        });
    },
    warning(msg: string, callBack?: () => void) {
        Modal.warning({
            title: '警告',
            content: msg,
            onOk() {
                if (callBack) {
                    callBack();
                }
            },
        });
    },
    loading: {
        show(msg: string = '加载中...') {
            const loading = document.getElementById('loading') as HTMLDivElement;
            const loadingContent = document.getElementById('loadingContent') as HTMLDivElement;
            loadingContent.innerHTML = msg;
            loading.style.display = 'block';
        },
        close() {
            const loading = document.getElementById('loading') as HTMLDivElement;
            loading.style.display = 'none';
        },
    },
    logeout(): Promise<void> {
        return storage.clear().then(() => {
            window.location.replace(process.env.VUE_APP_TPSS_PAGE);
        });
    },
};
export default BaseService;
