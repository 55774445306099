
import { Options, Vue } from 'vue-class-component';
import { typeList } from '../formwork.model';
import {
  ParamArray,
  ParamBoolean,
  ParamNumber,
  ParamObject,
  ParamString,
} from './param.model';
@Options({
  components: {},
  props: {
    option: ParamArray,
    asParam: Boolean,
    asParent: Boolean,
  },
  emits: {
    optionChange: Object,
  },
})
export default class ArrayParam extends Vue {
  private option!: ParamArray;
  private asParam?: boolean;
  private asParent?: boolean;
  set paramData(value: ParamArray) {
    this.option = value;
  }
  get paramData(): ParamArray {
    return this.option;
  }
  private readonly typeList = typeList.filter((r) => r !== 'boolean' && r !== 'array');
  public add() {
    const newParam = this.newParam(this.paramData.arrayType);
    if (this.paramData.list) {
      this.paramData.list.push(newParam);
    } else {
      this.paramData.list = [newParam];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public changeType() {
    const newParam = this.newParam(this.paramData.arrayType);
    if (newParam) {
      this.paramData.list = [this.newParam(this.paramData.arrayType)];
    } else {
      this.paramData.list = [];
    }
    this.$forceUpdate();
  }
  public newParam(type: string = 'string') {
    switch (type) {
      case 'string':
        return null;
      case 'number':
        return null;
      case 'object':
        return new ParamObject();
      default: return null;
    }
  }
  public optionChange() {
    // console.log(this.paramData);
    this.$emit('optionChange', this.paramData);
  }
}
