
import { Options, Vue } from 'vue-class-component';
import { typeList } from '../formwork.model';
import { ParamArray, ParamBoolean, ParamNumber, ParamObject, ParamString } from './param.model';
@Options({
  components: {},
  props: {
    option: ParamObject,
    asParam: Boolean,
    asParent: Boolean,
  },
  emits: {
    changeOption: ParamObject,
  },
})
export default class ObjectParam extends Vue {
  private option!: ParamObject;
  private asParam?: boolean = false;
  private asParent?: boolean = false;
  set paramData(value: ParamObject) {
    this.option = value;
  }
  get paramData(): ParamObject {
    return this.option;
  }
  private readonly typeList = typeList;
  public add() {
    if (this.paramData.list) {
      this.paramData.list.push(this.newParam());
    } else {
      this.paramData.list = [this.newParam()];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public changeType(type: string, index: number) {
    console.log(type);
    this.paramData.list[index] = this.newParam(type);
    this.$forceUpdate();
  }
  // tslint:disable-next-line:max-line-length
  public newParam(type: string = 'string'): ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray | undefined {
    switch (type) {
      case 'string': return new ParamString();
      case 'number': return new ParamNumber();
      case 'boolean': return new ParamBoolean();
      case 'array': return new ParamArray();
      case 'object': return new ParamObject();
      default: break;
    }
  }
  public optionChange() {
    this.$emit('optionChange', this.paramData);
  }
}
