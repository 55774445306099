/* eslint-disable */
import * as localforage from 'localforage';

const StorageServe = {
    // 获取值
    get<T>(key: string): Promise<T | any> {
        return localforage.getItem<T>(key);
    },
    // 保存值
    save<T>(key: string, value: T): Promise<T | any> {
        return localforage.setItem<T>(key, value);
    },
    // 删除值
    delete(key: string): Promise<any> {
        return localforage.removeItem(key);
    },
    // 清除值
    clear(): Promise<any> {
        return localforage.clear();
    },
    test() {
        console.log('test');
    },
};

export default StorageServe;
// {
//     install(Vue: any, options: any) {
//         Vue.prototype.$storage = StorageServe;
//     },
// };
