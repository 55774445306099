
import { Options, Vue } from 'vue-class-component';
import { ParamString } from './param.model';
@Options({
  components: {},
  props: {
    option: ParamString,
    asParam: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    optionChange: Object,
  },
})
export default class StringParam extends Vue {
  private option!: ParamString;
  private asParam = false;
  set paramData(value: ParamString) {
    this.option = value;
  }
  get paramData(): ParamString {
    return this.option;
  }
  public add() {
    if (this.paramData.list) {
      this.paramData.list.push({ value: null, description: '' });
    } else {
      this.paramData.list = [{ value: null, description: '' }];
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public remove(index: number) {
    if (this.paramData && this.paramData.list && this.paramData.list.length) {
      this.paramData.list.splice(index, 1);
    }
    this.$forceUpdate();
    this.optionChange();
  }
  public optionChange() {
    this.$emit('optionChange', this.paramData);
  }
}
