export class ParamString {
    public type: 'string' = 'string';
    // 参数名
    public name: string = '';
    // 描述
    public description: string = '';
    // 值
    public value?: string;
    // 枚举
    public list: Array<{ value: string | null, description: string }> = [];
}

// tslint:disable-next-line:max-classes-per-file
export class ParamNumber {
    public type: 'number' = 'number';
    public name: string = '';
    public description: string = '';
    public value?: number;
    // 是否有最大值
    public setMax: boolean = false;
    // 是否有最小值
    public setMin: boolean = false;
    public max: number | null = null;
    public min: number | null = null;
    // 枚举
    public list: Array<{ value?: number | null, description: string }> = [];
}

// tslint:disable-next-line:max-classes-per-file
export class ParamBoolean {
    public type: 'boolean' = 'boolean';
    public name: string = '';
    public description: string = '';
    public value?: boolean;
    // ture值描述
    public trueDesc: string = '';
    // false值描述
    public falseDesc: string = '';
}

// tslint:disable-next-line:max-classes-per-file
export class ParamObject {
    public type: 'object' = 'object';
    public name: string = '';
    public description: string = '';
    // 模板
    public model?: Array<ParamString|ParamNumber|ParamBoolean|ParamObject|ParamArray|undefined>;
    // 值列表同时也是模板列表
    public list: Array<ParamString|ParamNumber|ParamBoolean|ParamObject|ParamArray|undefined> = [];
}

// tslint:disable-next-line:max-classes-per-file
export class ParamArray {
    public type: 'array' = 'array';
    public name: string = '';
    public description: string = '';
    public arrayType: string = 'string';
    // 模板
    public model?: ParamObject|ParamArray;
    // 模板列表
    public list: Array<string|number|ParamObject|ParamArray|null> = [];
    // 值列表
    public value: Array<string|number|ParamObject|ParamArray|null> = [];
}
