<template>
  <div class="full">
    <router-view/>
    <div id="loading" class="flex full">
      <div class="relative full">
        <div class="loading abCenter flex">
          <LoadingOutlined style="fontSize:23px;"/>
          <div id="loadingContent">加载中...</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  LoadingOutlined,
} from '@ant-design/icons-vue';
@Options({
  components: {
    LoadingOutlined,
  },
})
export default class App extends Vue {
  public collapsed = true;
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#loading{
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: none;
  .loading{
    padding: 25px 30px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    #loadingContent{
      padding-left: 8px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
