
import { Options, Vue } from 'vue-class-component';
import {
  LoadingOutlined,
} from '@ant-design/icons-vue';
@Options({
  components: {
    LoadingOutlined,
  },
})
export default class App extends Vue {
  public collapsed = true;
}
