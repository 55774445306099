
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {},
})
export default class Login extends Vue {
  public mounted() {
    console.log(this.$route.query);
    const jwt = this.$route.query.jwt;
    if (jwt) {
      this.$storage.save('jwt', jwt).then(() => {
        this.$router.push('/public/vehicle');
      });
    } else {
      this.$base.error('缺失登录信息');
      this.$base.logeout();
    }
  }
}
