export class HttpConfig {
    public timeout = 3000;
    public responseType:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream' = 'json';
}
